<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> {{caption}}
        <div class="card-header-actions">
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        pagination
      >
        <template #id="{item}"><td class="w100 text-center">{{item.sale.id}}</td></template>
        <template #type="{item}"><td class="w200 text-center">{{item.amount == item.sale.total ? 'Venta' : 'Apartado'}}</td></template>
        <template #date="{item}"><td class="w200 text-center">{{item.date | date}}</td></template>
        <template #payment_form="{item}"><td class="w200 text-center">{{item.payment_form == "cash" ? "Efectivo" : (item.payment_form == "debit" ? "Tarjeta de Débito" : (item.payment_form == "credit" ? "Tarjeta de Crédito" : "Regalo"))}}</td></template>
        <template #amount="{item}"><td class="w200 text-right">$ {{item.amount | currency}}</td></template>
        <template #customer_name="{item}"><td class="text-center">{{item.sale.customer_name}}</td></template>
        <template #actions="{item}">
          <td class="table_actions">
            <CButton color="success" size="sm" v-if="item.amount == item.sale.total" @click="showSale(item.sale.id)"><CIcon name="cil-zoom"/></CButton>
            <CButton color="info" size="sm" v-if="item.amount != item.sale.total" @click="showPreSale(item.sale.id)"><CIcon name="cil-zoom"/></CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import ws from '../../services/payments';
import store from '../../store'

export default {
  name: 'PaymentsTable',
  components: { },
  props: {
    items: Array,
    permissions: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'id', label: '#'},
          {key: 'date', label: 'Fecha del Pago'},
          {key: 'type', label: 'Tipo'},
          {key: 'customer_name', label: 'Cliente'},
          {key: 'payment_form', label: 'Forma de Pago'},
          {key: 'amount', label: 'Monto del Pago'},
          {key: 'actions', label: '', class: 'actions' }
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      toasts: [],
    }
  },
  methods: {
    showSale (sale_id) {
      window.location.href = "/#/sales/"+sale_id+"/view";
    },
    showPreSale (sale_id) {
      window.location.href = "/#/presales/"+sale_id+"/view";
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
