<template>
  <div>
    <PaymentsTable
      :items="payments"
      hover
      striped
      border
      small
      fixed
      caption="Pagos Recibidos"
      @refresh="refreshItems"
    />
  </div>
</template>
<script>

import PaymentsTable from '../../components/payments/PaymentsTable.vue'
import payments from '../../services/payments';

import store from '../../store'

export default {
  name: 'Payments',
  components: {
    PaymentsTable
  },
  data: function () {
		return {
            payments: []
        }
  },
  mounted: async function() {
    this.loading();
    let response = await payments.get();

    if(response.type == "success"){
      this.payments = response.data;
    }

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.payments = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
